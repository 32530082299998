import React, {useEffect, useState} from 'react';
import Layout from '@components/domain/shared/layout';
import {useBreakpoint} from 'gatsby-plugin-breakpoints';
import {graphql, Link, navigate} from "gatsby";
import {useAuth0, withAuthenticationRequired} from '@auth0/auth0-react';
import Description from '@components/domain/course-details/description';
import EmptySpace from '@components/ui/empty-space';
import PropTypes from 'prop-types';
import Spacing from '@components/ui/spacing';
import styles from '@styles/templates/curso-candidatura.module.scss';
import Progress from '@components/domain/course-application/progress';
import Profile from '@components/domain/course-application/profile';
import Application from '@components/domain/course-application/application';
import Spinner from '@components/ui/spinner';
import Submission, {AlreadyApplied} from '@components/domain/course-application/submission';
import {HttpClient} from "@utilities/http-client";
import {validate} from '@components/domain/form';

const CursoCandidatura = (props) => {
  const [ step, setStep ] = useState(1);
  const [ user, setUser ] = useState();
  const [ profile, setProfile ] = useState({});
  const [ application, setApplication ] = useState({});
  const [ errors, setErrors ] = useState([]);
  const [ submissionError, setSubmissionError ] = useState();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const { data } = props;
  const course = data.course.nodes[0];
  const full_course_name = course.course.name + " " + course.iteration_name;
  const breakpoints = useBreakpoint();

  const httpClient = HttpClient(getAccessTokenSilently);

  // scroll to top on step change
  useEffect(() => () => {
    window.scrollTo(0, 0);
  }, [step]);

  // load user profile
  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    (async () => {
      try {
        const response = await httpClient("auth/");
        if (!response.ok) {
          console.error("An error has occured.", response.status);
          return;
        }

        const userJson = await response.json();
        setUser(userJson);

        const lastApplicationResponse = await httpClient("applications/last/");

        if (!lastApplicationResponse.ok) {
          console.error("An error has occured.", lastApplicationResponse.status);
          return;
        }

        const applicationJson = await lastApplicationResponse.json();

        // init profile form with existing data
        if (applicationJson) {
          setProfile({
            ...applicationJson, email: userJson.email
          });
        }else
          setProfile({
            email: userJson.email
          });

      } catch (error) {
        console.error("An error has occured.", error);
      }
    })();
  }, [isAuthenticated]);

  // submit application
  useEffect(() => {
    // only submit on step 3
    // only submit if not previously submitted
    if (step !== 3 || submissionError === true || submissionError === false) {
      return;
    }

    (async () => {
      try {
        // eslint-disable-next-line no-unused-vars
        const filtered_profile = (({course_iteration, email, course_iter_id, user_id, questions, ...others}) => ({...others}))(profile);

        const data = {
          course_iter_id: course.course_iter_id,
          ...filtered_profile,
          questions: application
        };

        const response = await httpClient("applications/", {
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });
  
        if (!response.ok) {
          console.error("An error has occured.", response.status);
          const errorJson = await response.json();
          setSubmissionError(errorJson.message || true);
          return;
        }
  
        setSubmissionError(false);
        setStep(3);
      } catch (error) {
        console.error("An error has occured.", error);
      }
    })();
  }, [step, submissionError]);

  const onPreviousStep = () => {
    setErrors([]);

    // go to courses page
    if (step === 1) {
      navigate('/candidatura/');
    }

    setStep(step > 1 ? step-1 : 1);
  };

  const onNextStep = () => {
    let errors = [];

    // validate step 1
    if (step === 1) {
      props.data.profile.questions.map(group => {
        group.fields.map(field => {
          errors = [ ...errors, ...validate(group, field, profile[field.id]) ];
        });
      });

      if (errors.length === 0){
        if (profile.email === profile.parent_email ){
          const email_corr = confirm(`Os dois emails inseridos são iguais. Confirma que o email do aluno é "${profile.email}"? Se não for, clique em "Cancelar", termine a sessão na sua conta e faça login com a conta do aluno.`);
          if (!email_corr)
            return;
        }
      }
    }

    // validate step 2
    if (step === 2) {
      course.application_questions?.map(group => {
        group.fields.map(field => {
          errors = [ ...errors, ...validate(group, field, application[field.id]) ];
        });
      });
    }

    setErrors(errors);

    if (errors.length === 0) {
      setStep(step < 3 ? step+1 : 3);
    }
  };

  // check if user already applied to this activity
  const alreadyApplied = user?.applications?.filter(function(e) { return e.course_iter_id === course.course_iter_id; }).length > 0;

  return (
    <Layout extraFooterPadding={breakpoints.mobile ? 200 : 0}>
      <EmptySpace desktop={{ margin: 120 }} mobile={{ margin: 100 }} />
      <div className={styles.container}>
        {user && !alreadyApplied && step < 3 &&
        <Spacing>
          <Progress step={step} stepCount={2} text={(step, stepCount) => `Passo ${step} de ${stepCount}`}/>
        </Spacing>
        }
        <EmptySpace desktop={{ margin: 30 }} mobile={{ margin: 20 }} />
        <Spacing>
          <div className={styles.metadata}>
            <section className={styles.overview}>
              <Description
                title="Candidatura"
                courseName={full_course_name}
                scholarity={course.scholarity}
              />
            </section>
          </div>
        </Spacing>
        <Spacing>
          {!user && <Spinner />}
          {user && alreadyApplied &&
          <>
            <AlreadyApplied />
            <div className={styles.controls}>
              <Link to={'/candidatura/'}>Voltar à página anterior</Link>
            </div>
          </>
          }
          {user && !alreadyApplied &&
          <>
            {step === 1 && <Profile courseId={course.course_iter_id} questions={props.data.profile.questions || []} profile={profile} setProfile={setProfile} />}
            {step === 2 && <Application courseId={course.course_iter_id} questions={course.application_questions || []} application={application} setApplication={setApplication} />}
            {step === 3 && <Submission loading={submissionError === undefined} error={submissionError} />}
            {errors.length > 0 &&
            <div className={styles.errors}>
              <div className={styles.title}>Erros de validação:</div>
              <ul>
              {errors.map((error, i) =>
                <li key={i}>{error.message}</li>
              )}
              </ul>
            </div>
            }
            {(step === 1 || step === 2) &&
            <div className={styles.controls}>
              <div className={styles.back}>
                <a onClick={onPreviousStep} disabled={step === 1}>Voltar</a>
              </div>
              <a className={styles.continue} onClick={onNextStep}>continuar</a>
            </div>
            }
            {step === 3 &&
            <div className={styles.controls}>
              <Link to="/">Voltar à página inicial</Link>
            </div>
            }
          </>
          }
        </Spacing>
      </div>
      <EmptySpace desktop={{ margin: 120 }} mobile={{ margin: 100 }} />
    </Layout>
  );
};

export default withAuthenticationRequired(CursoCandidatura);


export const pageQuery = graphql`
query CourseApplicationQuery($iteration_id: String) {
  course: allRestApiCourseIterations(filter: {course_iter_id: {eq: $iteration_id}}) {
    nodes {
      course {
        name
      }
      scholarity
      course_iter_id
      iteration_name
      application_questions {
        title
        fields {
          description
          id
          label
          max
          multiple
          options
          required
          type
        }
      }
    }
  }
  profile: profileJson {
    questions {
      title
      fields {
        id
        label
        description
        type
        options
        multiple
        required
        max
        disabled
      }
    }
  }
}
`;

CursoCandidatura.propTypes = {
  path: PropTypes.string,
  data: PropTypes.shape({
    course: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          course_iter_id: PropTypes.string,
          iteration_name: PropTypes.string,
          scholarity: PropTypes.string,
          course: PropTypes.shape({
            name: PropTypes.string
          }),
          application_questions: PropTypes.arrayOf(
            PropTypes.shape({
              title: PropTypes.string,
              fields: PropTypes.arrayOf(
                PropTypes.shape({
                  id: PropTypes.string,
                  label: PropTypes.string,
                  description: PropTypes.string,
                  type: PropTypes.string,
                  options: PropTypes.arrayOf(PropTypes.string),
                  disabled: PropTypes.bool,
                  multiple: PropTypes.bool,
                  required: PropTypes.bool,
                  max: PropTypes.number
                })
              )
            })
          )
        })
      )
    }),
    profile: PropTypes.shape({
      questions: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          fields: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              label: PropTypes.string,
              type: PropTypes.string,
              options: PropTypes.arrayOf(PropTypes.string),
              disabled: PropTypes.bool,
              required: PropTypes.bool,
              max: PropTypes.number
            })
          )
        })
      )
    })
  })
};
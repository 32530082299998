import React from 'react';
import PropTypes from 'prop-types';
import FormSubmission from '@components/domain/form/submission';

import "@fortawesome/fontawesome-svg-core/styles.css";
import {NotAllowedError} from "@components/domain/form/submission";

export const AlreadyApplied = () =>
  <NotAllowedError
    error="Já te candidataste a este curso."
    errorDescription="A tua candidatura está em análise. Os resultados serão anunciados por email, num prazo máximo de 5 dias após o término do prazo de candidatura."
  />;

const Submission = ({ loading, error }) =>
  <FormSubmission
    error={error}
    loading={loading}
    errorDescription="Erro ao submeter a candidatura"
    successDescription="Os resultados das candidaturas serão anunciados por email, num prazo máximo de 5 dias após o término do prazo de candidatura."
    successMessage="Candidatura submetida com sucesso."
  />;

export default Submission;

Submission.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
  ])
};

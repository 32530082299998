import React from 'react';
import PropTypes from 'prop-types';
import Form from '@components/domain/form';

const Profile = ({ courseId, questions, profile, setProfile }) => {
  return (
    <Form
      header="Caso seja a tua primeira candidatura, preenche a informação que se segue. Caso não seja a tua primeira candidatura, verifica se os dados estão atualizados antes de procederes para o passo seguinte.<br />A conta usada para fazer a candidatura deverá ser a <strong>conta criada com o email do(a) aluno(a)</strong>. Se a conta atual estiver associada ao email do(a) Encarregado(a) de Educação, por favor clique em 'Sair' e faça login com o email do aluno."
      courseId={courseId}
      questions={questions}
      values={profile}
      onChange={setProfile}
    />
  );
};

export default Profile;

Profile.propTypes = {
  courseId: PropTypes.string,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      fields: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          label: PropTypes.string,
          description: PropTypes.string,
          type: PropTypes.string,
          options: PropTypes.arrayOf(PropTypes.string),
          multiple: PropTypes.bool,
          required: PropTypes.bool,
          max: PropTypes.number,
        })
      )
    })
  ),
  profile: PropTypes.object,
  setProfile: PropTypes.func
};
